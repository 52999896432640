import React, {useRef, useEffect} from 'react';
import {useLocation, Switch} from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views
import Home from './views/Home';
import Recrutement from "./components/sections/Recrutement";
import APropos from "./components/sections/APropos/APropos";

// Initialize Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({page});
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
     <ScrollReveal
        ref={childRef}
        children={() => (
             <Switch>
               <AppRoute exact path="/" component={Home} layout={LayoutDefault}/>
               <AppRoute path="/recrutement" component={Recrutement}/>
               <AppRoute path="/a_propos" component={APropos} layout={LayoutDefault}/>
               <ToastContainer/>
             </Switch>
        )}/>
  );
}

export default App;
